<template>
    <div>
        <v-snackbar
        :color="color"
        :timeout="5000"
        top
        v-model="snackbar"
        >
            <v-row>
                {{text}}
                <v-spacer></v-spacer>
                <v-icon small color="white" @click="snackbar = false" class="ml-3">mdi-close</v-icon>
            </v-row>
        </v-snackbar>
        <v-container v-if="details">
            <span class="headline secondary--text font-weight-bold text-uppercase">{{details.Title}}</span>
            <span class="caption mt-3 ml-2">{{details.days}}</span>
            <p class="body-1 d-block mt-3" v-if="details.City"><v-icon left class="mb-1">mdi-map-marker</v-icon> {{details.City}}</p>
            <p class="body-1 d-block mt-3" v-if="details.hotelName"><v-icon left class="mb-1">mdi-domain</v-icon> {{details.hotelName}}</p>
            <p class="body-1 d-block mt-3" v-if="details['Hotel Name']"><v-icon left class="mb-1">mdi-domain</v-icon> {{details['Hotel Name']}}</p>
            <v-img :src="details.img" class="mt-5" max-height="500">
                <template v-slot:placeholder>
                <v-skeleton-loader
                    max-width="100%"
                    class="mx-auto"
                    type="image"
                    >
                    </v-skeleton-loader>
                </template>
            </v-img>
            <v-card color="#fff" class="mt-5">
                <v-tabs
                v-model="tab"
                background-color="transparent"
                color="secondary"
                icons-and-text
                grow
                >
                    <v-tab>
                        ITINERARY
                        <v-icon>mdi-check-all</v-icon>
                    </v-tab>
                    <v-tab v-if="pricesAvailable">
                        PRICES
                        <v-icon>mdi-currency-usd</v-icon>
                    </v-tab>
                </v-tabs>
                <v-divider></v-divider>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-timeline class="mr-5" dense>
                            <v-timeline-item
                            v-for="(vlue, name, index) in days"
                            :key="index"
                            color="secondary"
                            class="pr-5"
                            >
                            <template v-slot:icon>
                                <span class="text--center white--text">{{index + 1}}</span>
                            </template>
                            <v-card class="elevation-2">
                                <v-card-title class="headline secondary--text">
                                    {{name}}
                                </v-card-title>
                                <v-card-text class="primary--text body-2">
                                    {{vlue}}
                                </v-card-text>
                            </v-card>
                            </v-timeline-item>
                        </v-timeline>
                    </v-tab-item>
                    <v-tab-item>
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th v-for="(value, name, i) in details.prices" :key="i" class="text-center">
                                        <span class="text-caption text-sm-body-1 primary--text font-weight-bold">{{name}}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td v-for="(value, name, i) in details.prices" :key="i" class="text-center">
                                        <span v-if="value" class="text-caption text-sm-body-2 primary--text font-weight-bold text-uppercase">{{value.split(' ')[1]}} </span>
                                        <span v-if="value" class="text-caption text-sm-body-2 primary--text"> {{value.split(' ')[0]}}</span>
                                        <p v-else>--</p>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
            <v-row class="my-5">
                <v-card class="elevation-2 mx-2" width="100%">
                    <v-card-title>
                        You can send your enquiry via the form below.
                    </v-card-title>
                    <v-card-subtitle>
                        Package name: {{details.Title}}
                    </v-card-subtitle>
                    <v-card-text>
                        <v-divider></v-divider>
                        <v-form ref="form" v-model="valid">
                            <v-row>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field
                                    outlined
                                    v-model="client.name"
                                    :rules="[v => (!!v && v.length > 2) || 'Item is required at least 3 characters', v => /^[_A-z]*((-|\s)*[_A-z])*$/.test(v) || 'Name Must be letters only with no spaces']"
                                    label="Full Name"
                                    required
                                    color="blue"
                                    prepend-inner-icon="mdi-account"
                                    id="client-name"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" class="mb-5">
                                    <phoneInput @update="assignPhone" />
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field
                                    label="Email"
                                    v-model="client.email"
                                    type="email"
                                    prepend-inner-icon="mdi-email"
                                    :rules="[v => !!v || 'Email is required', v => /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(v) || 'E-mail must be valid']"
                                    color="secondary"
                                    outlined
                                    id="client-email"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete
                                    v-model="client.countryName"
                                    :rules="[v => !!v || 'This field is required']"
                                    :items="countries"
                                    item-text="name"
                                    item-value="name"
                                    label="Country"
                                    outlined
                                    prepend-inner-icon="mdi-flag"
                                    hide-no-data
                                    color="blue"
                                    autocomplete="off"
                                    :menu-props="{ auto: true, maxWidth: 'auto', overflowY: false }"
                                    id="client-country"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="6" sm="6" md="4">
                                    <v-text-field
                                    outlined
                                    prepend-inner-icon="mdi-account-multiple"
                                    v-model="client.adults"
                                    :rules="[v => !!v || 'This field is required']"
                                    label="Adults Number"
                                    type="number"
                                    required
                                    autocomplete="false"
                                    color="blue"
                                    id="adults-num"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" sm="6" md="4">
                                    <v-text-field
                                    outlined
                                    prepend-inner-icon="mdi-human-child"
                                    v-model="client.children"
                                    label="Children Number"
                                    type="number"
                                    required
                                    autocomplete="false"
                                    color="blue"
                                    id="children-num"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                    v-model="client.message"
                                    outlined
                                    name="input-7-4"
                                    label="Leave a message"
                                    color="blue"
                                    id="message"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-form>
                        <vue-recaptcha sitekey="6LfKsvMZAAAAAKIcmgiH5FoU3RMHeab2qma1s9y_" @verify="verify" :loadRecaptchaScript="true"></vue-recaptcha>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" class="elevation-0 mr-2 px-5" large :block="$vuetify.breakpoint.smAndDown" :disabled="!valid || !verified" @click="sendMail" :loading="loading">SEND EMAIL</v-btn>
                    </v-card-actions>
                </v-card>
            </v-row>
        </v-container>
        <p v-else class="text-center body-1 font-weight-bold mt-5">Sorry, Couldn't get this trip details.</p>
    </div>
</template>

<script>
import phoneInput from '../components/mobile.vue'
import { countries } from '../contants'
import { bookTrip, headersNoAuth } from '../links'
import VueRecaptcha from 'vue-recaptcha'

export default {
  title: '-Trip Details',
  components: {
    phoneInput,
    VueRecaptcha
  },
  data () {
    return {
      details: null,
      days: null,
      tab: null,
      pricesAvailable: false,
      valid: true,
      snackbar: false,
      color: '',
      text: '',
      //   initialPhone: '',
      phone: {},
      client: {
        tripTitle: '',
        phone: '',
        name: '',
        email: '',
        countryName: '',
        countryIsoCode: '',
        adults: '',
        children: '',
        message: ''
      },
      loading: false,
      verified: false
    }
  },
  computed: {
    countries () {
      return countries
    }
  },
  methods: {
    assignPhone (phone) {
      this.phone = phone
    },
    verify (res) {
      this.verified = true
    },
    sendMail () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.phone && this.phone.valid) {
          this.client.phone = this.phone.nationalNumber
          this.client.countryIsoCode = this.phone.countryCode
          this.client.tripTitle = this.details.Title
          this.loading = true
          this.$http.post(bookTrip, this.client, { headers: headersNoAuth() }).then(response => {
            this.loading = false
            if (response.status === 200) {
              this.snackbar = true
              this.color = 'success'
              this.text = 'We got your email and will contact you soon.'
            } else {
              this.snackbar = true
              this.color = 'error'
              this.text = response.body.message
            }
          }, error => {
            this.loading = false
            this.snackbar = true
            this.color = 'error'
            this.text = error.body.message
          })
        } else {
          this.snackbar = true
          this.color = 'error'
          this.text = 'Please provide a valid mobile number'
        }
      } else {
        this.snackbar = true
        this.color = 'error'
        this.text = 'Please fill required fields'
      }
    }
  },
  created () {
    if (this.$store.state.trip) {
      this.details = this.$store.state.trip
      if (this.details.details) this.days = this.details.details
      else this.days = this.details['details ']
      for (const prop in this.details.prices) {
        if (this.details.prices[prop]) {
          this.pricesAvailable = true
        }
      }
    }
  }
}
</script>

<style>
  .input-tel.lg .input-tel__input[data-v-e59be3b4], .country-selector.lg .country-selector__input[data-v-46e105de] {
    height: 56px !important;
    border: 1.2px solid #c9c9cb;
  }
  .v-tab {
    background-color: #fff;
    width: 15%;
  }
  .v-tab.v-tab--active {
    background-color: #fff;
  }
</style>
